import Header from '../../components/header/header';
import { useTranslation } from 'react-i18next';

export default function About(){
    const { t } = useTranslation('about');
    return(
        <>
        <Header/>
        <h1>{t('title')}</h1>
        </>
    );
}