import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useContext } from 'react';
import { ThemeContext } from './themeContext';
import Landpage from './components/landpage/landpage';
import Home from './pages/home/home';
import About from './pages/about/about';

function App() {
  return (
    <Router>
          <Routes>
            <Route index element={<Landpage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
    </Router>
  );
}
export default App;