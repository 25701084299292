import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from './i18n/en/common.json';
import landpageEn from './i18n/en/landpage.json';
import homeEn from './i18n/en/home.json';
import aboutEn from './i18n/en/about.json';
import headerEn from './i18n/en/header.json';

import commonEs from './i18n/es/common.json';
import landpageEs from './i18n/es/landpage.json';
import homeEs from './i18n/es/home.json';
import aboutEs from './i18n/es/about.json';
import headerEs from './i18n/es/header.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['common', 'landpage', 'home', 'header', 'about'], 
    resources: {
      en: {
        common: commonEn,
        landpage: landpageEn,
        home: homeEn,
        about: aboutEn,
        header: headerEn
      },
      es: {
        common: commonEs,
        landpage: landpageEs,
        home: homeEs,
        about: aboutEs,
        header: headerEs
      }
    },
    detection: {
      order: ['navigator', 'localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
      lookupQuerystring: 'lng', // Permite cambiar el idioma a través de la URL si es necesario
    },
    whitelist: ['es', 'en'], // Solo se permiten los idiomas español e inglés
    nonExplicitWhitelist: true, // Permite variantes de idioma como es-MX a es
    load: 'languageOnly',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;