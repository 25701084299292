import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('D'); // Por defecto, día

  useEffect(() => {
    const time = new Date();
    const hours = time.getHours();
    const currentTheme = hours >= 20 || hours < 7 ? 'Night' : 'Day';
    setTheme(currentTheme);
  }, []);

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};
