import Header from '../../components/header/header';
import { useTranslation } from 'react-i18next';


export default function Home(){
    const { t } = useTranslation('home');
    return(
        <>
        <Header/>
        <h1>{t('title')}</h1>
        </>
    );
}