import React, { useContext } from 'react';
import './styles/css/landpage.css';
import img from './images/walking man.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../themeContext';

export default function Landpage() {

  const theme = useContext(ThemeContext);
  const { t } = useTranslation('landpage');
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/home');
  };

  return (
    <div  id='background'>

    <div className={`${theme}`}>
        <article className='elementos'>
          <h1>{t('title')}</h1>
          <p>{t('description')}</p>
          <button onClick={handleButtonClick}>
            {t('buttonText')}
          </button>
        </article>

        <article className='piso'>
          <img src={img} alt="walking man" className={`${theme}svg-icon`}/>
        </article>
    </div>
      
    </div>
  );
}
