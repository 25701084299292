import './header.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Header() {
  const { t } = useTranslation('header');

  return (
    <header>
      <Nav>
        <ul>
          <li>
            <Link to="/home">{t('home')}</Link>
          </li>
          <li>
            <Link to="/about">{t('about')}</Link>
          </li>
        </ul>
      </Nav>
    </header>
  );
}

function Nav ({children}){
    return(
        <nav className='nav'>
            {children}
        </nav>
    );
}
